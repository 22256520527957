import React, { useState, useRef } from "react";
import { styled } from "../../stitches.config";
import Link from "../../components/Link";
// import List from '../../components/composition-components/box/List';
import { ReactComponent as LiveIcon } from "../../assets/img/live.svg";

import { useWindowWidth } from "../../helpers";
import Box from "../../components/composition-components/Box";
import NewsList from "./NewsList";

interface IPost {
  background: string;
  background_image: string;
  category: string;
  label: string;
  link: string;
  lockAt: number;
  locked: string;
  media: any;
  partner: string;
  player: string;
  search: string;
  show_author: boolean;
  show_links?: string;
  show_partner_logo?: boolean;
  title_normal: string;
}
interface IAd {
  text: string;
  link: string;
}
interface IList {
  label: string;
  postCategoryId: number | null;
  posts: Array<{
    link: string;
    title: string;
    labels?: string[];
    time: string;
  }>;
}
interface ISettings {
  b1: IPost;
  b2: IAd;
  bList: {
    lists: IList[];
  };
}

interface IBlock1ListProps {
  settings: ISettings;
  blockGroupHasBackground?: boolean;
  blockSettings: {
    tb_region?: string;
  };
}

const Block1List: React.FC<IBlock1ListProps> = ({ settings, blockSettings, ...props }) => {
  const { bList } = settings;
  const windowWidth = useWindowWidth();

  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="col-md-9" data-tb-region={blockSettings?.tb_region}>
            <Box
              {...props}
              id="b1"
              type="BTextOnPicture"
              settings={settings.b1}
              withBackground={props.blockGroupHasBackground ? 'with-padding' : null}
              classes=""
              imageHeight={(windowWidth > 996 ? 534 : 480)}
            />
          </div>
          <div className="col-md-3">
            <NewsList
              smallList={true}
              lists={bList.lists}
              style={props.blockGroupHasBackground ? 'with-background' : null}
              tbRegion={blockSettings?.tb_region}
              settings={settings.b2}
            />
          </div>
        </div>
      </div>
    </Root>
  );
};

const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  margin: "40px 0",
  // width: '100%',
  height: "auto",
  justifyContent: "space-between",
  "@bp4": {
    flexDirection: "row",
    // paddingBottom: "100px",
  },
});

export default Block1List;
